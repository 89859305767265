import type { ParsedUrlQuery } from "querystring";
import { Box, Button, CircularProgress } from "@barracuda-internal/bds-core";
import { useCsrfTokenContext } from "../../lib/components/CsrfTokenContext";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { getBase64DecodedLoginHintFromUrl, LOGIN_HINT_PARAMETER_NAME } from "../../lib/auth";
import { getLogger } from "@pam/common/logger";
import ErrorPage from "../_error";

export interface SignInPageQuery extends ParsedUrlQuery {
  callbackUrl: string;
  error?: string;
}

export interface SignInPageProps {
  callbackUrl: string;
  error?: string;
}

// eslint-disable-next-line @typescript-eslint/require-await
export const getServerSideProps: GetServerSideProps<SignInPageProps, SignInPageQuery> = async function ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  req,
  query,
}: GetServerSidePropsContext<SignInPageQuery>): Promise<GetServerSidePropsResult<SignInPageProps>> {
  // When not signed in, the next-auth provider ID is passed as an error for some reason, blame next-auth.
  let error = Array.isArray(query.error) ? query.error.join(", ") : query.error;
  // This will ensure we attempt to automatically redirect to Login App
  if (error && error === "bcc-login-app") error = undefined;

  if (error) {
    getLogger().error({ error: query.error }, "Detected authentication error");
  }
  const callbackUrl = Array.isArray(query.callbackUrl) ? query.callbackUrl.join(", ") : query.callbackUrl;

  return {
    props: {
      callbackUrl: callbackUrl || "",
      error,
    },
  };
};

export default function SignIn({ callbackUrl, error }: SignInPageProps) {
  const { data: session } = useSession();
  const router = useRouter();
  const csrfToken = useCsrfTokenContext();
  const formRef = React.createRef<HTMLFormElement>();
  let uri = "/api/auth/signin/bcc-login-app";

  if (callbackUrl?.includes(LOGIN_HINT_PARAMETER_NAME)) {
    const decodedLoginHint = getBase64DecodedLoginHintFromUrl(callbackUrl);
    if (decodedLoginHint) {
      uri += `?login_hint=${encodeURIComponent(decodedLoginHint)}`;
    }
  }

  useEffect(() => {
    if (session !== null) {
      // If the user is already logged in then send user back to main page
      void (async () => {
        await router.push("/");
      })();
    }

    // Do not trigger automatic sign in flow if there is an error
    if (!error && formRef.current) {
      // This is a hack because nextauth doesn't have simple ways to bypass custom/boilerplate sign in pages
      // in the oauth flow (client or server side). Technically you can start OAuth flow by issuing a post to /api/auth/signin/:provider
      // but the number of redirects to follow makes it more complex than just auto clicking the sign in button below
      // see https://next-auth.js.org/getting-started/rest-api#post-apiauthsigninprovider
      // see https://github.com/nextauthjs/next-auth/issues/1582
      formRef.current.submit();
    }
  }, [error, formRef, router, session]);

  if (error) {
    return (
      <>
        <ErrorPage statusCode="There was a problem during authentication." />
      </>
    );
  }

  return (
    <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
      <form ref={formRef} method="post" action={uri}>
        <>
          <CircularProgress size={40} style={{ margin: "2px" }} />
        </>

        <Box padding={3} style={{ width: "500px", margin: "0 auto" }}>
          <input type="hidden" name="csrfToken" value={csrfToken}></input>
          <input type="hidden" name="callbackUrl" value={callbackUrl}></input>
          <Button style={{ visibility: "hidden" }} type="submit" variant="contained" color="primary" size="medium">
            Sign in
          </Button>
        </Box>
      </form>
    </Box>
  );
}
